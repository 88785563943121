<template>
  <v-card
    class="mx-auto"
    max-width="200"
  >
    <v-img
      :src="product.image_url"
      @click="handleCardClick"
      height="300"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card-title @click="handleCardClick" class="cut-text">{{product.name}}</v-card-title>
    <v-card-subtitle class="pb-0">
      R {{ product.price }}
    </v-card-subtitle>
    <v-card-subtitle>
      <v-rating
        v-model="product.rating"
        background-color="orange lighten-3"
        color="orange"
        small
        readonly
      ></v-rating>
    </v-card-subtitle>


    <v-card-actions class="d-flex justify-space-between">
      <v-btn
        :class="{favorite: isFavorite}"
        text
        @click="handleFavorite"
      >
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn
        class="cursor-unset"
        color="orange"
        text
        @click="handleCardClick"
      >
        Details
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "ProductCard",
        props: ['product'],
        data() {
            return {
                isFavorite: false
            }
        },
        computed: {
            ...mapState({
                favorites: state => {
                    return state.favorites.items;
                },
            }),
        },
        watch: {
            favorites(newFavorites, oldFavorites) {
                this.defineIsInFavorites()
            }
        },
        mounted() {
            this.defineIsInFavorites()
        },
        methods: {
            handleCardClick() {
                this.$router.push({name: 'books.show', params: {slug: this.product.slug}})
            },
            async handleFavorite() {
                this.isFavorite
                    ? await this.$store.dispatch('favorites/removeFromFavorites', {product: this.product}, {root:true})
                    : await this.$store.dispatch('favorites/addToFavorites', {product: this.product}, {root:true})
            },
            defineIsInFavorites() {
                for(let i = 0; i < this.favorites.length; i++) {
                    if (this.favorites[i].id === this.product.id) {
                        this.isFavorite = true
                        return
                    }
                }
                this.isFavorite = false
            }
        }
    }
</script>

<style scoped>
  .cut-text {
    width: 100%;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
    .card {
        text-align: center;
    }
  .w-100 {
    width: 100%;
  }
</style>
